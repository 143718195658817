<template>
  <CHeader fixed with-subheader light>
    <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/admin">
      <CIcon :src="$config.logoUrl" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <!--      <CHeaderNavItem class="px-3">-->
      <!--        <CHeaderNavLink :to="{name:'admin.home'}">-->
      <!--          {{ getTranslation('dashboard') }}-->
      <!--        </CHeaderNavLink>-->
      <!--      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">

      <NotificationDropdown/>

      <DashboardDropdown/>

      <HeaderDropdownAccount/>

      <span class="page-header__icon-wrap ml-3" style="cursor: pointer" @click="logout">
         <svg class="c-icon">
        <use class="c-icon" xlink:href="#icon__log-out"></use>
      </svg>
      </span>

    </CHeaderNav>


    <CSubheader class="px-3">
      <ul class="c-subheader-nav d-md-down-none mr-auto">
        <CBreadcrumb class="border-0 mb-0" :items="$store.getters['heading/getBreadcrumbs']"/>
      </ul>
    </CSubheader>


  </CHeader>
</template>

<script>

import HeaderDropdownAccount from "@/layouts/admin/HeaderDropdownAccount";
import DashboardDropdown from "@/layouts/admin/DashboardDropdown";
import UserFunctions from "@/classes/UserFunctions";
import NotificationDropdown from "./NotificationDropdown";

export default {
  name: "Header",
  components: { NotificationDropdown, DashboardDropdown, HeaderDropdownAccount },
  methods: {
    logout() {
      this.axios.post(this.$urls.auth.logout.url).then(() => {
        UserFunctions.removeUserInfo();
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
.c-subheader {
  align-items: first baseline;
}
</style>