<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/admin">
      <CIcon
          class="c-sidebar-brand-full"
          :src="$config.logoUrl"
          size="custom-size"
          :height="55"
          viewBox="0 0 556 134"
      />
      <CIcon
          class="c-sidebar-brand-minimized"
          :src="$config.logoUrl"
          size="custom-size"
          :height="48"
          viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <ul class="c-sidebar-nav">
      <li class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'admin.home'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__home"></use>
          </svg>
          {{ getTranslation('home') }}
        </router-link>
      </li>
      <li v-if="hasPermission('application.getList')" class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'application.getList'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__layers"></use>
          </svg>
          {{ getTranslation('applications') }}
        </router-link>
      </li>
      <li v-if="hasPermission('application.requestCallback.getList')" class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'application.requestCallback.getList'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__layers"></use>
          </svg>
          {{ getTranslation('requestCallbacks') }}
        </router-link>
      </li>
      <li v-if="hasPermission('company.getList')" class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'company.getList'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__subdivisions"></use>
          </svg>
          {{ getTranslation('companies') }}
        </router-link>
      </li>
      <li v-if="hasPermission('client.getList')" class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'client.getList'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__users"></use>
          </svg>
          {{ getTranslation('clients') }}
        </router-link>
      </li>
      <li v-if="hasPermission('coupon.getList')" class="c-sidebar-nav-item">
        <router-link class="c-sidebar-nav-link" :to="{name:'coupon.getList'}">
          <svg class="c-sidebar-nav-icon">
            <use xlink:href="#icon__favorites"></use>
          </svg>
          {{ getTranslation('coupons') }}
        </router-link>
      </li>
    </ul>
    <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>

export default {
  name: 'Sidebar',
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
