<template>
  <div class="page-layout__heading">
    <div class="page-layout__heading-inner">
      <div v-if="$store.getters['heading/getHeadings'].toBack.label" class="page-layout__back-btn">
        <router-link
            :to="$store.getters['heading/getHeadings'].toBack.route.name === $store.getters['heading/getLastRoute'].name ? $store.getters['heading/getLastRoute'] : $store.getters['heading/getHeadings'].toBack.route"
            class="btn  btn--with-icon  p-0  m-0">
          <svg class="btn__icon">
            <use xlink:href="#icon__arrow-2-left"></use>
          </svg>
          {{ $store.getters['heading/getHeadings'].toBack.label }}
        </router-link>
      </div>
    </div>
    <div class="page-layout__heading-inner">
      <div class="d-flex  align-items-center">
        <h2 class="page-layout__title">{{ $store.getters['heading/getTitle'] }}</h2>
        <span v-if="$store.getters['heading/getAppInfo'].status" class="ml-2 badge"
              :class="{'badge-success':$store.getters['heading/getAppInfo'].status==='NEW' || $store.getters['heading/getAppInfo'].status==='AGENT_NEW',
              'badge-warning':$store.getters['heading/getAppInfo'].status==='WAITING_PROVIDER' || $store.getters['heading/getAppInfo'].status==='AGENT_SELECTED',
              'badge-danger':$store.getters['heading/getAppInfo'].status==='REJECTED' || $store.getters['heading/getAppInfo'].status==='AGENT_REJECTED' || $store.getters['heading/getAppInfo'].status==='AGENT_EXPIRED',
        'badge-primary':$store.getters['heading/getAppInfo'].status==='CONFIRMED' || $store.getters['heading/getAppInfo'].status==='AGENT_CONFIRMED'}
">
          {{ getTranslation('APPLICATION_' + $store.getters["heading/getAppInfo"].status) }}
        </span>
        <span v-if="$store.getters['heading/getAppInfo'].is_client_paid && !$store.getters['user/getUser'].isAgent" class="ml-2 badge badge-danger">
          <CIcon  :name="'cil-cash'"/>  {{ getTranslation('isClientPaid') }}
        </span>
        <span v-if="$store.getters['heading/getCompanyInfo'].status" class="ml-2 badge"
              :class="{'badge-success':$store.getters['heading/getCompanyInfo'].status==='NEW',
              'badge-danger':$store.getters['heading/getCompanyInfo'].status==='REJECTED',
              'badge-primary':$store.getters['heading/getCompanyInfo'].status==='CONFIRMED'}">
          {{ getTranslation('COMPANY_' + $store.getters["heading/getCompanyInfo"].status) }}
        </span>
      </div>
      <div v-if="$store.getters['heading/getActions'].length > 0" class="page-layout__heading-filler"></div>
      <div v-if="$store.getters['heading/getActions'].length > 0" class="page-layout__controls">
        <span v-for="(action, idx) in $store.getters['heading/getActions']" v-bind:key="idx" class="ml-3">

          <CLink v-if="action.route" :to="action.route">
           <span class="text-capitalize" > {{ action.label }}</span>
          </CLink>

           <button v-if="action.action" @click="action.action" type="button"
                   :class="'btn ' + ((action.icon || action.c_icon) ? 'btn--with-icon ' : ' ') + (action.buttonClass || 'btn--white  btn--system ')">
                        <svg v-if="action.icon" :class="'btn__icon ' + (action.icon.classes || '')">
                          <use :xlink:href="action.icon.icon"></use>
                        </svg>
                           <CIcon v-if="action.c_icon" :name="action.c_icon"/>

                         <span class="text-capitalize" > {{ action.label }}</span>
           </button>

        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Heading"
}
</script>

<style scoped>

</style>