<template>
  <div class="c-app page-layout__main">
    <pace-component/>
    <toaster-component/>
    <Sidebar/>
    <CWrapper>
      <Header/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <Heading/>
            <router-view :key="$route.path"></router-view>

          </CContainer>
        </main>
      </div>
      <Footer/>
    </CWrapper>
  </div>
</template>

<script>
import Sidebar from "@/layouts/admin/Sidebar";
import Header from "@/layouts/admin/Header";
import Footer from "@/layouts/admin/Footer";
import Heading from "@/layouts/admin/Heading";
import PaceComponent from "@/components/PaceComponent";
import ToasterComponent from "@/layouts/admin/ToasterComponent";

export default {
  name: "Main",
  components: { ToasterComponent, PaceComponent, Heading, Footer, Header, Sidebar }
}
</script>

<style lang="scss" >
// Import Main styles for this application
@import '../../assets/scss/style';
@import "../../assets/css/custom.css";
@import "../../assets/css/custom-layout.css";
</style>