<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
              :src="$config.noProfilePhotoUrl"
              class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $store.getters['user/getUser'].username }}</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-user"/>
      Profile
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'HeaderDropdownAccount'
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>