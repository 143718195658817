<template>
  <CFooter :fixed="false" class="mt-5">
    <div>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Cargo JET</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1">Powered by</span>
      CoreUI for Vue
    </div>
  </CFooter>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>

</style>