<template>
  <CDropdown
      inNav
      size="lg"
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-settings"/>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
        tag="div"
        class="text-center"
        color="light"
    >
      <strong>{{ getTranslation('adminka') }}</strong>
    </CDropdownHeader>
    <CDropdownItem v-if="hasPermission('auth.levels.getList')" :to="{name:'auth.levels.getList'}">
      <CIcon name="cil-lock-locked"/>
      {{ getTranslation('levels') }}
    </CDropdownItem>
    <CDropdownItem  v-if="hasPermission('lang.getList')" :to="{name:'lang.getList'}">
      <CIcon name="cil-bookmark"/>
      {{ getTranslation('languages') }}
    </CDropdownItem>
    <CDropdownItem  v-if="hasPermission('auth.logins')" :to="{name:'auth.logins'}">
      <CIcon name="cil-address-book"/>
      {{ getTranslation('loginJournal') }}
    </CDropdownItem>
    <CDropdownItem  v-if="hasPermission('user.getList')" :to="{name:'user.getList'}">
      <CIcon name="cil-people"/>
      {{ getTranslation('users') }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "DashboardDropdown",
  data() {
    return {
      levels: [],
    }
  },
  created() {
    this.setHeadings({
      title: this.getTranslation('Levels'),
      breadcrumbs: [
        {
          text: this.getTranslation('Levels'),
        }
      ]
    })
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>