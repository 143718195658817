<template>
  <CToaster :autohide="false">
    <template v-for="(toast,key) in items">
      <CToast
          :key="'toast' + key"
          :show="true"
          :close-button="true"
          :header="toast.serviceCategorySlug ? getTranslation(toast.serviceCategorySlug) : toast.title"
      >
        {{ getTranslation(toast.message) }}
      </CToast>
    </template>
  </CToaster>
</template>

<script>
import UserFunctions from "../../classes/UserFunctions";

export default {
  name: "ToasterComponent",
  data() {
    return {
      items: []
    }
  },
  computed: {
    currentUserId() {
      return this.$store.getters['user/getUserId'];
    },
  },
  created() {
    this.connect();
    this.bindChannels();
  },
  methods: {
    /** Connect Echo **/
    connect() {
      this.$echo.connector.pusher.config.auth.headers.Authorization = UserFunctions.getUserToken();

    },
    /** Bind Channels **/
    bindChannels() {
      this.$echo.private('App.Models.User' + '.' + this.currentUserId)
          .notification((object) => {
            this.items.push(object);
          });
    },
  }
}
</script>

<style scoped>

</style>