<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-bell"/>
        <span class="badge rounded-pill badge-danger">{{ items.length }}</span>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ getTranslation('notifications') }}</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="(item,key) in items" v-bind:key="key" @click="read(item)">

      <div class="message">
        <div>
          <span class="text-muted" v-if="item.notification === 'database'"> {{ item.data.serviceCategorySlug ? getTranslation(item.data.serviceCategorySlug): item.data.title }}</span>
          <span class="text-muted" v-else> {{ item.serviceCategorySlug ? getTranslation(item.serviceCategorySlug) : item.title }}</span>
          <div class="text-muted small float-right mt-1 ml-1">{{ item.created_at }}</div>
        </div>
        <div class="text-truncate font-weight-bold"> {{ getTranslation(item.notification === 'database' ? item.data.message : item.message) }}</div>
      </div>
    </CDropdownItem>

  </CDropdown>
</template>

<script>

import UserFunctions from "../../classes/UserFunctions";

export default {
  name: 'NotificationDropdown',
  data() {
    return {
      counter: 0,
      items: [],
      echo: null
    }
  },
  computed: {
    currentUserId() {
      return this.$store.getters['user/getUserId'];
    },
  },

  created() {
    this.fetchNotifications().then(() => {
      this.connect();
      this.bindChannels();
    });
  },
  methods: {

    /** Bind Channels **/
    bindChannels() {
      this.$echo.private('App.Models.User' + '.' + this.currentUserId)
          .notification((object) => {
            this.items.push(object);
          });
    },

    /** Connect Echo **/
    connect() {
      this.$echo.connector.pusher.config.auth.headers.Authorization = UserFunctions.getUserToken();
    },

    read(item) {

      if ( !this.hasPermission('user.profile.readNotification') )
        return;

      this.axios.post(this.$urls.user.readNotification.url.replace(':id', this.$store.getters['user/getUserId']), {
        notification_ids: [ item.id ]
      }).then(() => {

        this.fetchNotifications();
        let route = {};

        if ( item.notification === 'database' )
          route = item.data.route;
        else
          route = item.route;

        this.$router.push(route).catch(() => {
        })
      }).catch(() => {
      });

    },
    fetchNotifications() {

      return this.axios.get(this.$urls.user.notifications.url.replace(':id', this.$store.getters['user/getUserId']), { params: { type: 'unread' } })
          .then(response => {
            this.items = response.data.data.items;
            this.counter = response.data.data.pagination.total;
          }).catch(() => {
          });
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>